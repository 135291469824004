/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import useReactQuery from 'hooks/react_query'

const urls = window.Urls

export function useUsers(params) {
    return useReactQuery<User[]>(urls.userList(), ['User', 'list', params], { queryParams: params })
}

export function useExtendedUser(id: number) {
    return useReactQuery<ExtendedUser>(urls.extendedUserDetail(id), ['ExtendedUser', 'detail', id])
}

export function useActiveUser() {
    return useReactQuery<ActiveUser>(urls.activeUser(), ['ActiveUser'])
}

export function fetchCourseInstructors() {
    return axios.get<any[]>(urls.courseInstructorsList()).then(res => res.data)
}

export function useCourseInstructors() {
    return useQuery({ queryFn: fetchCourseInstructors, queryKey: ['CourseInstructor', 'list'] })
}

export function useCourseAuthors(params?) {
    return useReactQuery<any[]>(urls.authorList(), ['CourseAuthor', 'list', params], { queryParams: params })
}
